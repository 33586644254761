/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import faviconImgUrl from '../images/favicon.ico';
// import { useStaticQuery, graphql } from 'gatsby';
// import ttSocialCardUrl from '../images/tt-social-card.png';

function SEO({ lang, meta, title, image }) {
  const metaDescription = 'TrueFi is DeFi’s first and largest credit protocol. Lenders enjoy predictable, transparent yields on real world and crypto-native lending. Borrowers and portfolio managers get access to competitively priced capital with no collateral lockup.';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      link={[
        {
          rel: `icon`,
          type: `image/png`,
          href: faviconImgUrl,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `og:url`,
          content: `http://truefi.io/`,
        },
        {
          property: `og:title`,
          content: 'TrueFi Uncollateralized Loans | High Yield Lending & Borrowing Without Collateral',
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: `https://d3n32ilufxuvd1.cloudfront.net/5e8f7529722272005d1c6e41/2350777/screenshot-d55fb93a-bb95-4b2f-9c1a-d0722e54673a_readyscr_1024.jpg`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ]
        .concat({
          name: `keywords`,
          content: `uncollateralised lending high yield crypto returns`,
        })
        .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
